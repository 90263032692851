<template>
	<Table
		:pgLimitGet="1000"
		backendDirectory="filial/listar"
		:busca="{
            busca: busca.busca || null
        }"
		:filter="true"
		:keydown="keydown"
		:headers="{
            idfilial: {nome: 'ID', tipo: permissao('filial_get') ? 'link' : 'texto', link: '/filial/get/'},
            filial: {nome: 'Filial', tipo: 'texto'},
            endereco: {nome: 'Endereço', tipo: 'texto'},
            cep: {nome: 'CEP', tipo: 'cep', class: 'text-truncate'},
            contato: {nome: 'Contato', tipo: 'contato', class: 'text-truncate'},
        }"
	>
		<v-col>
			<v-text-field
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				@keydown.enter="keydown = !keydown"
			/>
		</v-col>
	</Table>
</template>

<script>
import Table from "../Widgets/Table";

export default {
	name: "FilialLista",
	components: { Table },
	data: () => ({
		busca: {},
		keydown: false,
	}),
};
</script>

<style scoped>
</style>